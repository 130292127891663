<template>
  <div class="bg-pink">
    <div class="q-pa-md absolute-center">
      <q-spinner color="pink" size="10em" :thickness="5" />
    </div>
  </div>
</template>
<script setup>
import checkAuth from "@/router/middleware/checkAuth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted } from "vue";
import { setLoginRoute } from "@/modules/partner/storage";

defineComponent({
  name: "ConfigRedirectComponent"
});

const route = useRoute();
const router = useRouter();

const fullPath = route.fullPath;
const parentSlug = route.params.slug;

const getPathAfterRedirect = fullPath.split("/redirect/")[1]; 
const goToPath = getPathAfterRedirect?.split("#")[0]; // rota a ser redirecionado
const hasTab = getPathAfterRedirect?.split("#")[1]; // Tem tab para ser ativada?


// METHODS

// Usuário autenticado
const handleAuthenticatedUser = () => {
  if ( hasTab ) {
    setLoginRoute(getPathAfterRedirect)
  }

  switch (goToPath) {
    case 'mission':
      showFeedback()

      redirectTo({
        name: 'view-mission'
      })
    break;

    case 'missions':
      showFeedback()

      redirectTo({
        name: 'missions'
      })
    break;

    case 'commission':
      redirectTo({
        name: 'commission'
      })
    break;
    default:
      redirectTo({
        name: goToPath
      })
    break;
  }
};


// Usuário não autenticado
const handleUnauthenticatedUser = () => {
  redirectTo({
    name: 'login',
    params: { slug: parentSlug }
  })
}


// Redireciona para rota
const redirectTo = (routerProps) => {
  router.push(routerProps);
}


// Salva a rota e a tab no LocalStorage
const showFeedback = () => {
  localStorage.setItem(
    "showFeedBack",
    JSON.stringify({
      tab: goToPath,
      slug: hasTab,
    })
  );
}
// METHODS


onMounted( async () => {
  try {
    const userIsAuthenticated = await checkAuth();

    if ( userIsAuthenticated ) handleAuthenticatedUser()
    else handleUnauthenticatedUser()

  } catch (error) {
    console.log('Houve um erro no ConfigRedirectComponent:', error)
  }
})
</script>
